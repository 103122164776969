import { ChangelogDataModel } from './app/modules/changelog/changelog-page/changelog.model';

export const changelogData: ChangelogDataModel[] = [
  {
    date: '11.13.2024',
    version: '1.1.39',
    titleUpdate: 'New Features and Bug Fixes',
    img: 'assets/new-img/changelog/changelog-1.1.10.png',
    changesGroups: [
      {
        label: {
          title: 'Common',
          color: 'primary',
        },
        listChanges: [
          { name: 'Improved the displaying of the Get QR button in the Offer' },
          { name: 'Updated buttons on the Affiliate and Advertiser profile pages' },
          { name: 'Unified the naming of affiliate-related fields across the CRM' },
          { name: 'Improved activity management for offers and affiliates' },
          { name: 'Added the Affiliate Group condition to the Drilldown Report' },
        ],
      },
      {
        label: {
          title: 'Bugs',
          color: 'red',
        },
        listChanges: [
          { name: 'Fixed 2FA limitations' },
          { name: 'Fixed the width of the Earnings field in the Invoice and Payment creation windows' },
        ],
      },
    ],
  },
  {
    date: '10.30.2024',
    version: '1.1.38',
    titleUpdate: 'New Features and Bug Fixes',
    img: 'assets/new-img/changelog/changelog-1.1.4.png',
    changesGroups: [
      {
        label: {
          title: 'Common',
          color: 'primary',
        },
        listChanges: [
          { name: 'Unified currency-related texts' },
          { name: 'Added the missing localization to conditions' },
          { name: 'Added the Payment Method column to the downloadable Earnings file' },
        ],
      },
    ],
  },
  {
    date: '10.23.2024',
    version: '1.1.37',
    titleUpdate: 'New Features and Bug Fixes',
    img: 'assets/new-img/changelog/changelog-1.1.3.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved Advertisers and Affiliates tables' },
          { name: 'Added the Status attribute to GET Affiliates and Offers API requests' },
          { name: 'Added the Total Paid column to the Earnings table' },
          { name: 'Unified the displaying of toggles across the platform' },
          { name: 'Added Conversion Profile fields to the Groups in Drilldown Reports' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed the usage of several quick filters in tables' }],
      },
    ],
  },
  {
    date: '10.16.2024',
    version: '1.1.36',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.1.2.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Removed excessive generation of CSV Importer presets' },
          { name: 'Added the affiliate registration date to the API response' },
          { name: 'Improved metrics management tools to secure from an accidental loss of settings' },
          { name: 'Adjusted the display of the sidebar for various screen resolutions' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed the logging out process when credentials are changed' }],
      },
    ],
  },
  {
    date: '10.09.2024',
    version: '1.1.35',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.1.1.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added the QR code option to the Offer Tracking Link' },
          { name: 'Improved currency management throughout the Billing section' },
          { name: 'Updated icons across the platform' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed UI texts across the platform' },
          { name: 'Fixed the name of the Edit Domain button on The Tracking Domain page' },
        ],
      },
    ],
  },
  {
    date: '10.02.2024',
    version: '1.1.34',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.1.0.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved the Label filter in the Conversion Profile table' },
          { name: 'Synced Referral Transactions with offer currency' },
          { name: 'Added the Conversion ID placeholder to Affiliate Trackers' },
          { name: 'Added the Affiliate Manager condition to Drilldown Reports' },
          { name: 'Added the Multicurrency support to the No Negative Carryover feature' },
          { name: 'Renamed the Status column into Activity in the Affiliate tab of the Data Exporter' },
          { name: 'Improved and unified filters across  the CRM' },
          { name: 'Improved the Create Earning button in the financial section' },
          { name: 'Improved UI texts across  the CRM' },
          { name: 'Added tooltip to the Referral Program modal window' },
          { name: 'Optimized the response time of Dashboard widgets' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the display of settings information in the  Offer profile' },
          { name: 'Fixed missing translation across the CRM' },
          { name: 'Fixed the logging of the wrong goal error that could occur in CSV Importer' },
        ],
      },
    ],
  },
  {
    date: '09.24.2024',
    version: '1.1.33',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.0.13.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Moved the support chat toggle' },
          { name: 'Improved platform functionality under high load' },
          { name: 'Renewed the UI of the entire platform' },
          { name: 'Improved the Add Affiliate button' },
          { name: 'Optimized Drilldown Reports capacity' },
          { name: 'Improved the speed of the Dashboard widgets and other elements' },
          { name: 'Added the support of additional languages to the Aff Sub parameters' },
          { name: 'Unified the date format for the PDF invoices' },
          { name: 'Unified naming for quick timeframe filters on Dashboard' },
          { name: 'Renamed the Company field in Advertisers and Affiliates tables' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the displaying of table quick filter on mobile devices' },
          { name: 'Fixed the Range section of the Drilldown Report' },
          { name: 'Fixed the display of the Paused affiliate status in the Data Exporter' },
          { name: 'Fixed the color indicators of Advertiser and Affiliate statuses' },
          { name: 'Fixed the display of the Vertical field values in tables' },
        ],
      },
    ],
  },
  {
    date: '09.19.2024',
    version: '1.1.32',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.0.12.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added the affiliate name placeholder to the offer URL' },
          { name: 'Improved data protection of the platform' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed the display of long values in Drilldown Report columns' }],
      },
    ],
  },
  {
    date: '09.09.2024',
    version: '1.1.31',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.0.11.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Multicurrency to the CRM: it’s fully compatible with the dashboard, data tables, reports, and billing' },
        ],
      },
    ],
  },
  {
    date: '08.29.2024',
    version: '1.1.30',
    titleUpdate: 'New Features',
    img: 'assets/new-img/changelog/changelog-1.0.10.svg',
    changesGroups: [
      {
        label: {
          title: 'Common',
          color: 'primary',
        },
        listChanges: [
          { name: 'Added a switch for the Support widget' },
          { name: 'Added API throttling per token' },
          { name: 'Added the bulk status change option in the Conversions table' },
          { name: 'Corrected the list of users in drop-down lists across the CRM' },
          { name: 'Synced language management across all CRMs' },
        ],
      },
      {
        label: {
          title: 'Bugs',
          color: 'red',
        },
        listChanges: [
          { name: 'Fixed tooltip modal windows on Dashboard' },
          { name: 'Fixed Affiliate Managers’ registration links' },
          { name: 'Fixed Validation for Qualification rules' },
          { name: 'Fixed the Paused status for Advertisers' },
          { name: 'Fixed the operation of Affiliate Invoice Consolidation with Metrics' },
          { name: 'Fixed validation of Conversion ID in the Conversions table' },
          { name: 'Fixed the display of the date in Comparing Analytics' },
          { name: 'Fixed the display of the last item in the table in Comparing Analytics' },
        ],
      },
    ],
  },
  {
    date: '2024-08-12',
    titleUpdate: 'New Features',
    version: '1.1.29',
    img: 'assets/new-img/changelog/changelog-1.0.9.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added an option to enable Deeplinks for Offers via API' },
          { name: 'Added the Source column to the Data Exporter preview' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed creation of Affiliate Invoice Consolidations' },
          { name: 'Fixed Comparing Analytics' },
          { name: 'Fixed the behavior of the Generate Drilldown Preview button when the required fields are empty' },
          { name: 'Fixed caching of previously passed data in the Deals Updater' },
          { name: 'Removed redundant quotes and localization-related typos' },
        ],
      },
    ],
  },
  {
    date: '2024-08-05',
    titleUpdate: 'New Features',
    version: '1.1.28',
    img: 'assets/new-img/changelog/changelog-1.0.8.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added the multiselect option to the hash filter in the Clicks table' },
          { name: 'Removed Old metrics' },
          { name: 'Added the Click creation date filters to the Conversions table' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the behavior of the Affiliate table filters after leaving the Unsecured Mode' },
          { name: 'Fixed Data Exporter preview with enabled conditions' },
          { name: 'Fixed autofill for the telegram ID field in the Affiliate Creation window' },
        ],
      },
    ],
  },
  {
    date: '2024-07-29',
    titleUpdate: 'New Features',
    version: '1.1.27',
    img: 'assets/new-img/changelog/changelog-1.0.7.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added Deeplinks for Offers' }],
      },
    ],
  },
  {
    date: '2024-07-17',
    titleUpdate: 'New Features',
    version: '1.1.26',
    img: 'assets/new-img/changelog/changelog-1.0.6.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Removed Affiliate Group from Advertiser profile' },
          { name: 'Added currency to Transactions' },
          { name: 'Improved 2FA modal window' },
          { name: 'Improved response time of the API Tokens table' },
          { name: 'Added the sweepstakes vertical to Offers' },
          { name: 'Added Expiration date filters for the API Tokens table' },
          { name: 'Unified the design of Support buttons across the Platform' },
          { name: 'Removed the Auto-update option from the Dashboard header' },
          { name: 'Improved field validation behavior' },
          { name: 'Switched field types in Offer Creation Wizard with few option to radio buttons' },
          { name: 'Completed localization for the Settings, Reports, profile pages and the Financial section' },
          { name: 'Improved Dashboard customization' },
          { name: 'Added filters for protected fields in the Affiliates table' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed an issue with the Paid status of Earnings' },
          { name: 'Removed outdated links from the registration form' },
          { name: 'Fixed IDs of conversions in notifications' },
          { name: 'Fixed values in the XLSX DRilldown Reports' },
          { name: 'Fixed validation of Payment’s source' },
        ],
      },
    ],
  },
  {
    date: '2024-07-11',
    titleUpdate: 'New Features',
    version: '1.1.25',
    img: 'assets/new-img/changelog/changelog-1.0.5.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added an extensive public API with most of the functionality of the Platform' }],
      },
    ],
  },
  {
    date: '2024-06-19',
    titleUpdate: 'New Features',
    version: '1.1.24',
    img: 'assets/new-img/changelog/changelog-1.0.4.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added the “Has Conversions” column to the Clicks table' },
          { name: 'Added the option to change statuses for several Invoices and Payments at once' },
          { name: 'Unified date fields across the CRM' },
          { name: 'Simplified the date filters on the Dashboard' },
          { name: 'Returned the Affiliate Manager preset to Drilldown Reports' },
          { name: 'Improved the reloading of the Drilldown Report table' },
          { name: 'Changed the filter icon for tables across the platform' },
          { name: 'Added support of Conversion Profiles to Affiliate Trackers' },
          { name: 'Adjusted the downloadable versions of the Earnings and Data Exporter reports' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the color of weekdays in the date picker' },
          { name: 'Removed a duplication of the “aff_sub_3” field in the Click Profile' },
          { name: 'Fixed the displaying of long column names in Drilldown Report' },
          { name: 'Fixed the displaying of numeric values in CSV versions of Data Exporter' },
          { name: 'Fixed naming of Payment profile pages' },
          { name: 'Fixed the displaying of the registration page on mobile devices' },
          { name: 'Fixed the CRM Metrics page' },
          { name: 'Fixed texts in system notifications' },
          { name: 'Fixed PQL rules for Referral Program' },
          { name: 'Synced the displaying of custom Drilldown Report presets' },
          { name: 'Fixed the displaying of the default value for the Click Session Lifespan' },
        ],
      },
    ],
  },
  {
    date: '2024-06-17',
    titleUpdate: 'New Features',
    version: '1.1.23',
    img: 'assets/new-img/changelog/changelog-1.0.3.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Improved API token management' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added Free PQL option to Targeting rules' }],
      },
    ],
  },
  {
    date: '2024-05-27',
    titleUpdate: 'New Features',
    version: '1.1.22',
    img: 'assets/new-img/changelog/changelog-1.0.2.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added the Archived status for Earnings' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added recording conversion errors to Event Logs' },
          { name: 'Added recording actions in the Main Tree to Event Logs' },
          { name: 'Extended timeframe for pulling sale statuses from the Lead Distribution' },
          { name: 'Added a permission for enabling and disabling the protected mode' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the amount of groupers in the downloaded Drilldown Report' },
          { name: 'Fixed displaying of Affiliate ID and name columns in the downloaded Drilldown Report' },
          { name: 'Fixed the toolbar of empty top charts in the Dashboard' },
          { name: 'Fixed displaying of network metrics in Drilldown Reports for Advertiser Area' },
          { name: 'Fixed displaying of the date selector for top charts in the Dashboard' },
        ],
      },
    ],
  },
  {
    date: '2024-05-15',
    titleUpdate: 'New Features',
    version: '1.1.21',
    img: 'assets/new-img/changelog/changelog-1.1.21.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added assignment of pending affiliates to affiliate managers' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved payment method field in PDF Invoices' },
          { name: 'Integrated affiliate consolidation earnings with widgets' },
          { name: 'Unified region and country code records' },
          { name: 'Added access control for goal types used in the affiliate global trackers' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Returned the Archived toggle in the affiliate edit menu' },
          { name: 'Fixed 2FA error that could happen when trying to view protected fields in the affiliate profile' },
          { name: 'Fixed displaying of downloadable Drilldown Reports for tables with only one metric included' },
        ],
      },
    ],
  },
  {
    date: '2024-05-08',
    titleUpdate: 'New Features',
    version: '1.1.20',
    img: 'assets/new-img/changelog/changelog-1.1.20.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added an option to download Earnings of all affiliates' },
          { name: 'Added localization to Portuguese' },
          { name: 'Updated Advertiser Area' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Unified the date format across the CRM' },
          { name: 'Improved tools for assigning affiliates to affiliate managers' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the depiction of the first name in the Payment info on affiliate page' },
          { name: 'Fixed the display of the Win amount metric in the Dashboard widgets' },
          { name: 'Fixed the Quick Filters for the Affiliate Groups page' },
          { name: 'Fixed the Conversions table within the click profile page' },
          { name: 'Fixed table mode switching after visiting the previous page in a browser' },
          { name: 'Fixed the amount of displayed metrics in downloadable Drilldown Reports' },
          { name: 'Fixed typos across the CRM' },
        ],
      },
    ],
  },
  {
    date: '2024-04-18',
    titleUpdate: 'New Features',
    version: '1.1.19',
    img: 'assets/new-img/changelog/changelog-1.1.19.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added Affiliate Invoice Consolidation' }, { name: 'Added the Earnings model to Metrics' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Improved the date filter in Trees' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed condition duplication in Drilldown Reports' },
          { name: 'Fixed the display of conditions for the flat mode of Drilldown Reports' },
          { name: 'Fixed the data field options for the Transactions model in Metrics' },
        ],
      },
    ],
  },
  {
    date: '2024-04-15',
    titleUpdate: 'New Features',
    version: '1.1.18',
    img: 'assets/new-img/changelog/changelog-1.1.18-new.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added a new Conversion Profile format to support Multicurrency in Traffic Data tables and Data Exporter' },
          { name: 'Added PDF version of invoices' },
          { name: 'Added the Earnings model to Metrics' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Optimized Drilldown Reports and the usage of groupers' }, { name: 'Added the date PQL filter to Trees' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed relation fields in Affiliate and Offer groupers of Drilldown Reports' },
          { name: 'Fixed the Affiliate Group filter in the Traffic Data tables' },
          { name: 'Fixed the empty data in the AFM Date field for manually created conversions' },
          { name: 'Fixed typos in Settings Navigation' },
          { name: 'Fixed the display of values in quick filters of traffic data tables' },
          { name: 'Fixed condition duplication in Drilldown Reports' },
          { name: 'Fixed the issue when an error occurred after changing a tab in Data Exporter' },
          { name: 'Fixed the data field options for the Transactions model in Metrics' },
        ],
      },
    ],
  },
  {
    date: '2024-03-21',
    titleUpdate: 'New Features',
    version: '1.1.17',
    img: 'assets/new-img/changelog/changelog-1.1.17-new.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added Affiliate Invoice Consolidation' }, { name: 'Added the Partner Platform App' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved display of the first column in the Drilldown table' },
          { name: 'Updated tooltips across the CRM' },
          { name: 'Added a date column switch in the date picker in the Payments tab of the Data Exporter' },
          { name: 'Added the platform update notification' },
          { name: 'Changed color for the “Cancel” button in the 2FA modal window' },
          { name: 'Optimized the Earnings page by removing unused data' },
          { name: 'Improved functionality of Free Parameters: now they can be passed via Traffic Back URL' },
          { name: 'Included Affiliate Managers in API responses on affiliates' },
          { name: 'Synced click geolocation detection with the Lead Distribution' },
          { name: 'Added filters for Conversion Profiles to the Conversion table and Data Exporter' },
          { name: 'Improved design of URL Replacer' },
          { name: 'Optimized conversion pulling process' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed slow loading of table items in Invoices and payments' },
          { name: 'Fixed display of the Information widget in the Affiliate page' },
          { name: 'Fixed creation of Payout and Revenue rules for duplicated offers' },
          { name: 'Fixed display of columns in Drilldown when only a few from many metrics are added' },
          { name: 'Fixed the “Set Paid” button in Earnings that could lead to errors' },
          { name: 'Fixed an error that occurred on the Affiliate page if there aren’t Referral Program folders' },
          { name: 'Fixed conditions in Comparing Analytics, Referral Program, and Data Exporter' },
          { name: 'Fixed offer duplication' },
          { name: 'Fixed editing of several Conversion Profile fields with the same name' },
          { name: 'Fixed the affiliate filter on the Earnings page' },
          { name: 'Fixed warning about unsaved changes when leaving the Earnings profile' },
          { name: 'Fixed display of total values in widget when Earnings is created for selected affiliate for the first time' },
          { name: 'Fixed display of data in quick filter for tables in Traffic Data' },
        ],
      },
    ],
  },
  {
    date: '2024-03-11',
    titleUpdate: 'New Features',
    version: '1.1.16',
    img: 'assets/new-img/changelog/changelog-1.1.16-new.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Referral Program' },
          { name: 'Added Multicurrency' },
          { name: 'Added Conversion Qualification to the Main Tree' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Configured the default value of the “Fire Postback” field in the Conversion Approval window' },
          { name: 'Added the “Approved” filter to the Earnings page' },
          { name: 'Updated tooltips across the CRM' },
          { name: 'Added the Conversion Profiles widget to Data Exporter' },
          { name: 'Added the “Region” column to Data Exporter' },
          { name: 'Improved Master Fee rules' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed Payout and Revenue passing rules' },
          { name: 'Fixed data displayed when tabs are quickly switched in the Main Tree' },
          { name: 'Fixed the cumulative option of the Aggregated CSV Importer' },
          { name: 'Fixed default date of the CSV Importer date picker' },
          { name: 'Fixed quick filter pop-up window concealment on the Earnings page' },
          { name: 'Fixed data updates for the widget on the Earnings calculation page' },
          { name: 'Fixed offer description presets: the changes applied to the preset are also applied to all offers it’s used in' },
          { name: 'Fixed the default value of the “Type” field in Transactions' },
          { name: 'Fixed the sorting by the “Change History” column in the Affiliates Requests table' },
          { name: 'Fixed date picker options on the mobile version of the Dashboard' },
          { name: 'Fixed the “Duplicate Offer” button visibility on the mobile version of the Offer page' },
          { name: 'Fixed the drop-down list in the “Condition Value” field in the new Metrics' },
          { name: 'Fixed the “Affiliate & Country” rule in Targeting' },
          { name: 'Fixed display of Network Metrics with the “percentage” format' },
          { name: 'Fixed sorting of the Transaction table by the “Transaction Date” column' },
        ],
      },
    ],
  },
  {
    date: '2024-02-21',
    titleUpdate: 'New Features',
    version: '1.1.15',
    img: 'assets/new-img/changelog/changelog-1.1.15-new.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added the “Prevent Negative Values” feature for Base Metrics' },
          { name: 'Optimized the Earnings page for a high load' },
          { name: 'Added pagination within each Earnings List' },
          { name: 'Added the option to approve conversions in Earnings Lists' },
          { name: 'Improved design and adjusted the Earnings page for mobile devices' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved Logs for Global Tracker' },
          { name: 'Improved design for all tables across the CRM' },
          { name: 'Adjusted the mobile version of the Comparing Analytics page' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the date picker position in filters of traffic data tables' },
          { name: 'Fixed date picker values reset after clicking preset tabs in Drilldown' },
          { name: 'Fixed display of money values in drilldown tables when two or more groupers are added' },
          { name: 'Fixed 2FA for the conversion editing option if the conversion is protected' },
          { name: 'Fixed the Compared Analytics table when no data was found' },
          { name: 'Fixed calculation of negative values in metrics' },
          { name: 'Fixed the widgets coherence with date pickers on Dashboard' },
          { name: 'Fixed the Offer Questions page' },
          { name: 'Fixed Cumulative option in the Aggregated CSV Importer' },
          { name: 'Fixed removal of First Name and Last Name fields is saved' },
          { name: 'Fixed the usage of the “Applied” status filter in Invoices' },
        ],
      },
    ],
  },
  {
    date: '2024-01-24',
    titleUpdate: 'New Features',
    version: '1.1.14',
    img: 'assets/new-img/changelog/changelog-1.1.14.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added Offer Duplication' }, { name: 'Added Comparing Analytics' }, { name: 'Added CSV Importer' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved Offer URLs Replacer' },
          { name: 'Improved design of the offer table' },
          { name: 'Added the new date picker' },
          { name: 'Added payout and revenue models in conditions of Drilldown' },
          { name: 'Added the “Country and Region” filter to offer request forms' },
          { name: 'Added affiliate activity details in Drilldown Reports' },
          { name: 'Added access settings for Conversion Profile data' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the invoice target in new metrics' },
          { name: 'Removed minimum and maximum dates from new metrics' },
          { name: 'Fixed display of payout models in offers' },
          { name: 'Fixed smartlinks for master affiliates' },
          { name: 'Fixed the issue when two earnings couldn’t be created in a row' },
          { name: 'Added en error message for the Provided Fields widget when advertiser wasn’t integrated' },
        ],
      },
    ],
  },
  {
    date: '2023-12-26',
    titleUpdate: 'New Features',
    version: '1.1.13',
    img: 'assets/new-img/changelog/changelog-1.1.13.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added the “Country and Region“ filter into Traffic Data tables' },
          { name: 'Added the Offer Requests table' },
          { name: 'Added Offer preview image' },
          { name: 'Added Marketing Info to Offers' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added deposit amount to Drilldown Reports' },
          { name: 'Added Brazilian real (BRL) to currencies' },
          { name: 'Removed Affiliate manager grouper from Drillrown Reports' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the sorting indicator when changing pages in the Depositors table' },
          { name: 'Fixed the Country field in offer wizard' },
        ],
      },
    ],
  },
  {
    date: '2023-12-04',
    titleUpdate: 'New Features',
    version: '1.1.12',
    img: 'assets/new-img/changelog/changelog-1.1.12.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added new Metrics' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added the “Country“ field in the Offer Wizard' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed comments visibility on Affiliate Profile' }],
      },
    ],
  },
  {
    date: '2023-10-25',
    titleUpdate: 'New Features',
    version: '1.1.11',
    img: 'assets/new-img/changelog/changelog-1.1.11.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added the Conversion Profile' }, { name: 'Added Advertiser Provided Fields' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added Conversion Profile fields to Qualification rules (baseline)' },
          { name: 'Added Conversion Profile fields to free PQL' },
          { name: 'Added Conversion Profile fields to CSV Importer' },
        ],
      },
    ],
  },
  {
    date: '2023-10-12',
    titleUpdate: 'New Features',
    version: '1.1.10',
    img: 'assets/new-img/changelog/changelog-1.1.10.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added an option to configure available currencies' },
          { name: 'Added notifications for changed payment methods' },
          { name: 'Integrated payment methods with invoices and payments' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added tooltips' }, { name: 'Added 2FA to affiliate payment info' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed telegram ID validation for affiliates' },
          { name: 'Fixed the display of payment methods in the Invoices table' },
          { name: 'Fixed fields in payment methods' },
          { name: 'Fixed the display of wire transfer in payment methods' },
          { name: 'Fixed minor payment method issues' },
          { name: 'Fixed 2FA security for the Account field in the Affiliate table' },
        ],
      },
    ],
  },
  {
    date: '2023-09-14',
    titleUpdate: 'New Features',
    version: '1.1.9',
    img: 'assets/new-img/changelog/changelog-1.1.19.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added the “Threshold per Affiliate” filter for Earnings' }, { name: 'Added IP Whitelisting for CRM users' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added the “Copy” button to the Aff Sub column' },
          { name: 'Added new fields in Main Tree for managing Affiliate Area' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed the issue when offer URLs could be invalid due to return to the next line' }],
      },
    ],
  },
  {
    date: '2023-08-28',
    titleUpdate: 'New Features',
    version: '1.1.8',
    img: 'assets/new-img/changelog/changelog-1.1.18.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Bonuses for Affiliates' },
          { name: 'Added a filter for hiding empty payouts in Earnings' },
          { name: 'Added date filters for Earnings' },
          { name: 'Improved 2FA enabling process and design in Affiliate Area' },
          { name: 'Added 2FA protection for financial data in Affiliate Area' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added a filter for hiding empty payouts in Earnings' },
          { name: 'Added payment data to Earnings' },
          { name: 'Removed the old Invoice widget from Affiliate’s profile' },
          { name: 'Improved logging of changes column in Invoices' },
          { name: 'Tooltips for Offers Creation added' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed displaying of long company names in the sidebar' },
          { name: 'Fixed “Save as Draft” button in Earnings' },
          { name: 'Fixed displaying data in Data Exporter conditions' },
          { name: 'Fixed displaying of an error when opening a non-existing Earnings page' },
          { name: 'Fixed displaying of data in Date Exporter' },
          { name: 'Fixed Event Logs' },
          { name: 'Fixed default values in date picker in Data Exporter' },
          { name: 'Fixed error message for changing default payment method' },
        ],
      },
    ],
  },
  {
    date: '2023-08-10',
    titleUpdate: 'New Features',
    version: '1.1.7',
    img: 'assets/new-img/changelog/changelog-1.1.17.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added table switch to Earnings' },
          { name: 'Added checkboxes to Earnings' },
          { name: 'Added actions selector to Earnings' },
          { name: 'Added an option to link Payouts to Earnings' },
          { name: 'Changed calculation of values in top widget in Earnings in Affiliate Area' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added Affiliate Group ID to Data Exporter' },
          { name: 'Adjusted date picker for Earnings in Data Exporter' },
        ],
      },
    ],
  },
  {
    date: '2023-07-24',
    titleUpdate: 'New Features',
    version: '1.1.6',
    img: 'assets/new-img/changelog/changelog-1.1.16.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added mass earnings creation' }, { name: 'Improved Payment methods' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved table design in Drilldown reports' },
          { name: 'Added decimal numbers to Payouts and Revenues' },
          { name: 'Added ERC to Payment Methods' },
          { name: 'Added the modal window if payment method wasn’t specified' },
          { name: 'Added Earnings to Data Exporter' },
          { name: 'Added privacy setting for Offer URLs' },
          { name: 'Added Quick Approval button in Earnings' },
          { name: 'Added Sign In button as Affiliate to Dashboard' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed error with CSV downloading in Drilldown' }],
      },
    ],
  },
  {
    date: '2023-07-03',
    titleUpdate: 'New Features',
    version: '1.1.5',
    img: 'assets/new-img/changelog/changelog-1.1.15.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added file management in Invoices' },
          { name: 'Improved searching through filters in tables' },
          { name: 'Optimized Payout Models' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed display of affiliate info in Affiliate Postbacks' },
          { name: 'Fixed default selection of tabs in Main Tree' },
          { name: 'Fixed display of Affiliate info in Affiliates table' },
          { name: 'Fixed alignment in External ID column, Traffic Data table' },
        ],
      },
    ],
  },
  {
    date: '2023-05-31',
    titleUpdate: 'New Features',
    version: '1.1.4',
    img: 'assets/new-img/changelog/changelog-1.1.4.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Finance Earnings. It allows to collect all payment information for any affiliate and correct it' },
          { name: 'Added Finance Invoices' },
          { name: 'Added Finance Payments' },
          { name: 'Added Unique Clicks to identify unique leads by cookies, IP-addresses and User Agent data' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          {
            name: 'Added Prelanders to Marketing Tools. Now advertisers can share combinations of HTML code and ZIP files for affiliates to add complex objects on their sites',
          },
          { name: 'Removed Affiliate Invoices' },
          { name: 'Added Affiliate Offer Access widget to Affiliate page. It allows to hide or show offers to  affiliates' },
        ],
      },
    ],
  },
  {
    date: '2023-05-22',
    titleUpdate: 'New Features',
    version: '1.1.3',
    img: 'assets/new-img/changelog/changelog-1.1.3.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          {
            name: 'Improved Conversion Table Settings in Affiliate Tree. Added a new rule which allows to show  affiliates Deposit Amount column',
          },
          { name: "Added Advertiser External ID column for Conversions table. It allows It records data from advertisers' resources" },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Optimized conversion pulling' }, { name: 'Renamed  Baseline Satisfy  into Qualify across the CRM' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed win and bet metrics in Drilldown: they used to be inverted' },
          { name: 'Fixed empty Drilldown groups. Now it’s forbidden to create a Drilldown Report with empty groups' },
          { name: 'Fixed display of Tree rules right after creation on Affiliate page' },
          { name: 'Fixed disappearance of nodes after using Drag&Drop in Trees' },
          { name: 'Fixed display of metrics on Dashboard widgets. They used to show zero value after changing settings' },
        ],
      },
    ],
  },
  {
    date: '2023-05-15',
    titleUpdate: 'New Features',
    version: '1.1.2',
    img: 'assets/new-img/changelog/changelog-1.1.2.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Improved scrolling in Drilldown' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Redesigned Affiliate page, new widgets now perfectly adjust to the screen resolution' },
          { name: 'Minor design changes in the offer creation wizard' },
        ],
      },
    ],
  },
  {
    date: '2023-04-27',
    titleUpdate: 'New Features',
    version: '1.1.1',
    img: 'assets/new-img/changelog/changelog-1.1.1.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added an option to pause inactive affiliates' },
          { name: 'Added Fees for Affiliate Masters' },
          { name: 'Added White Labeling' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added a referral link to Affiliate Groups' }],
      },
    ],
  },
  {
    date: '2023-04-12',
    titleUpdate: 'New Features',
    version: '1.1.0',
    img: 'assets/new-img/changelog/changelog-1.1.0.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added main tree' },
          { name: 'Added tree settings' },
          { name: 'Added permissions for trees' },
          { name: 'Added tree widgets to Affiliate and Offer pages' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved design of folders and rules in trees' },
          { name: 'Removed the settings features from CRM to the trees' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed the display of affiliate questions in drilldown' },
          { name: 'Fixed registration of the new affiliates' },
        ],
      },
    ],
  },
  {
    date: '2023-03-09',
    titleUpdate: 'New Features',
    version: '1.0.13',
    img: 'assets/new-img/changelog/changelog-1.0.13.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved login via 2FA' },
          { name: 'Changed goals to auto-approved by default in creation modals' },
          { name: 'Separated filters for global and default trackers' },
          { name: 'Added individual proxy for advertisers' },
          { name: 'Added affiliates synchronization' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed resetting password menu' },
          { name: 'Fixed Error response after resetting password' },
          { name: 'Fixed chargeback for conversions' },
        ],
      },
    ],
  },
  {
    date: '2023-01-18',
    titleUpdate: 'New Features',
    version: '1.0.12',
    img: 'assets/new-img/changelog/changelog-1.0.12.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added a pop-up list with Affiliate groups' },
          { name: 'Added validation for quick filters' },
          { name: 'Added pulling of Sale Status from Lead Distribution' },
          { name: 'Added metrics for invoices and transactions' },
          { name: 'Added filters for Master Affiliates' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed order of additional options in Offer Link' }],
      },
    ],
  },
  {
    date: '2022-12-30',
    titleUpdate: 'New Features',
    version: '1.0.11',
    img: 'assets/new-img/changelog/changelog-1.0.11.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Master Affiliate to Affiliate Groups' },
          { name: 'Added an hours filter to Data Exporter' },
          { name: 'Added selectors for device info' },
          { name: 'Improved Drilldown design' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed display of browser info in Drilldown Reports' },
          { name: 'Fixed Affiliate Access widget in Offer ID' },
        ],
      },
    ],
  },
  {
    date: '2022-12-22',
    titleUpdate: 'New Features',
    version: '1.0.10',
    img: 'assets/new-img/changelog/changelog-1.0.10.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added no negative carryover' },
          { name: 'Added Transactions table' },
          { name: 'Added Notifications for manually created Invoices' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added copy button to Affiliate Trackers' },
          { name: 'Moved Pixel to Goal Types' },
          { name: 'Added Notifications for manually created Invoices' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed Approved conversions date for affiliates' }, { name: 'Fixed free PQL rule editing' }],
      },
    ],
  },
  {
    date: '2022-12-08',
    titleUpdate: 'New Features',
    version: '1.0.9',
    img: 'assets/new-img/changelog/changelog-1.0.9.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added filters to Drilldown' }, { name: 'Added import of Conversions via CSV' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed display of Risk Management fields' },
          { name: 'Fixed Quick Filters in Click ID' },
          { name: 'Fixed Drilldown Reports with Manager ID Group' },
          { name: 'Fixed required fields in PQL rules for Payouts' },
          { name: 'Fixed API Token display' },
          { name: 'Fixed Basic Info Display in Affiliate ID' },
          { name: 'Fixed page selector for archived Offers' },
          { name: 'Fixed relation columns display in Drilldown Reports' },
        ],
      },
    ],
  },
  {
    date: '2022-11-28',
    titleUpdate: 'New Features',
    version: '1.0.8',
    img: 'assets/new-img/changelog/changelog-1.0.8.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added manually created invoices' },
          { name: 'Added Affiliate Fee' },
          { name: 'Added Marketing Tools' },
          { name: 'Improved Baseline' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added Baseline Types' },
          { name: 'Improved Cumulative Baseline' },
          { name: 'Added Types for API Tokens' },
          { name: 'Fixed Postbacks for Conversions with satisfied Baseline' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed a Typo in invoices Table' }],
      },
    ],
  },
  {
    date: '2022-11-15',
    titleUpdate: 'New Features',
    version: '1.0.7',
    img: 'assets/new-img/changelog/changelog-1.0.7.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added new fields for Net Gaming' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed Deals Updater' },
          { name: 'Fixed table display in Offer ID' },
          { name: 'Fixed error in Baseline Cumulative creation process' },
        ],
      },
    ],
  },
  {
    date: '2022-10-18',
    titleUpdate: 'New Features',
    version: '1.0.6',
    img: 'assets/new-img/changelog/changelog-1.0.6.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added filter groups in all sections' },
          { name: 'Added Affiliate Questions filters in Traffic Data' },
          { name: 'Added quick filters in all sections' },
          { name: 'Added telegram notifications' },
          { name: 'Added new notification types' },
          { name: 'Added Know Your Client module (KYC)' },
          { name: 'Added extra check for unique Conversions' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improved OS recognition in Clicks' },
          { name: 'Added extra info for Drilldown Data' },
          { name: 'Added Conditions for Drilldown Reports' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed empty Answer fields in Clicks' },
          { name: 'Fixed duplication in Clicks' },
          { name: 'Fixed a typo in Offers' },
          { name: 'Fixed Answers display in Clicks' },
        ],
      },
    ],
  },
  {
    date: '2022-09-04',
    titleUpdate: 'New Features',
    version: '1.0.5',
    img: 'assets/new-img/changelog/changelog-1.0.5.png',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Renamed Baseline into Baseline Setting' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed grouping in Drilldown reports' }],
      },
    ],
  },
  {
    date: '2022-08-29',
    titleUpdate: 'New Features',
    version: '1.0.4',
    img: 'assets/new-img/changelog/changelog-1.0.4.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added Dashboard Metrics customisation' }, { name: 'Added Conversions table in Click info' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Optimized Click registration' }, { name: 'Improved table settings in Drilldown Reports' }],
      },
    ],
  },
  {
    date: '2022-08-12',
    titleUpdate: 'New Features',
    version: '1.0.3',
    img: 'assets/new-img/changelog/changelog-1.0.3.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Pre-selected filters for Clicks' },
          { name: 'New Edit Answer in Affiliate Questions' },
          { name: 'New Signed Documents' },
          { name: 'New Approve Conversion Button' },
          { name: 'Added default filter for Conversion table' },
          { name: 'Added Fire Postback to Approve Conversion' },
          { name: 'Improved Skipper' },
          { name: 'Added ability to predefine table view in Metrics' },
          { name: 'Added Goal Type ID in Conversion' },
          { name: 'Added component Integer in Conversions' },
          { name: 'Added placeholders in Generate Postback Link' },
          { name: 'Improved filters' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Moved Auto Approve field to Goals' },
          { name: 'Added Free Parameters in Affiliate info' },
          { name: 'Renamed Report Builder into Data Exporter' },
          { name: 'Added Affiliate Responses in Click Table' },
          { name: 'Removed Skip First from Global Skipper' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed sorting dashboard statistics by metric order' },
          { name: 'Fixed Submit button' },
          { name: 'Fixed Company Name setting' },
          { name: 'Fixed a typo in Offer Edit module' },
          { name: 'Fixed Goal type conversions' },
          { name: 'Fixed an error after selecting conditions in Drilldown Reports' },
          { name: 'Fixed Archived Affiliate trackers' },
          { name: 'Fixed fields blocking in Goals' },
          { name: 'Fixed a dropdown list in Goals' },
          { name: 'Fixed Affiliate Tracking Domain' },
        ],
      },
    ],
  },
  {
    date: '2022-08-02',
    titleUpdate: 'New Features',
    version: '1.0.2',
    img: 'assets/new-img/changelog/changelog-1.0.2.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added Baseline Cumulative' },
          { name: 'Improved Global Skipper' },
          { name: 'New Endpoint for conversions' },
          { name: 'Added Advertiser conditions' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed Finance & Tools table' }],
      },
    ],
  },
  {
    date: '2022-07-20',
    titleUpdate: 'New Features',
    version: '1.0.1',
    img: 'assets/new-img/changelog/changelog-0.9.05.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added free parameters for offer URL' },
          { name: 'Goal privacy settings: ability to hide Goal from affiliate' },
          { name: 'Global and Local Skipper for conversions' },
          { name: 'Affiliate Baseline settings' },
          { name: 'Fee management' },
          { name: 'Shortcuts for Drilldown presets in the sidebar menu' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Improve Metrics Service (new format, metrics by the advertiser, goal type, etc.)' },
          { name: 'Add new fields to Presets configuration: order and visibility of columns, a default range' },
          { name: `New 'Flat' presentation for Drill Down Reports data` },
          { name: 'Optimize Partners Platform performance' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fix small bug of grouping in DrillDown Report' }],
      },
    ],
  },
  {
    date: '2022-07-01',
    titleUpdate: 'IREV Release',
    version: '1.0.0',
    img: 'assets/new-img/changelog/changelog-0.9.10.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Manual approving for Conversions' },
          { name: 'Extend Payouts' },
          { name: 'Extend Targeting feature' },
          { name: 'Manual Postbacks approval' },
          { name: 'New Invoice Create modal window' },
          { name: 'Payout widget' },
          { name: 'Slack Notifications' },
          { name: 'Full Payment Info in detailed Invoice page' },
          { name: 'New Branding' },
          { name: 'Add condition to Report Builder' },
          { name: 'New Charts' },
          { name: 'New Dashboard' },
          { name: 'Settings and more for Partners Platform' },
          { name: 'New Deposit amount and Withdrawal Amount values' },
          { name: 'Now you can Create Conversions through Clicks' },
          { name: 'Risk Management for creating Conversions' },
          { name: 'New privacy value “Pre-moderation“ for Offers' },
          { name: 'New Affiliate Access design' },
          { name: 'New Offers page.' },
          { name: 'Added New privacy to Goals in Offers' },
          { name: 'Offers changes' },
          { name: 'New Balances page' },
          { name: 'Partner signup' },
          { name: 'Moved Affiliate Trackers' },
          { name: 'New Comment Box widget' },
          { name: 'Added button to login as Advertises' },
          { name: 'Added Advertiser acceptance settings' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Changed Private column from Yes/No to Statuses' },
          { name: 'Made buttons locked in Affiliate area while offer is on pre-moderation' },
          { name: 'Made a button to request an approval from affiliate side' },
          { name: 'Made Notifications for affiliate manager' },
          { name: 'Added new Offer statuses' },
          { name: 'Added redirect Offer type' },
          { name: 'Added new Offer category' },
          { name: 'Added traffic sources to Offer page' },
          { name: 'Changed column name from “Is Private” to Privacy in Offers page' },
          { name: 'Added traffic back URL for offers' },
          { name: 'Added the ability to choose a redirect Offer type' },
          { name: 'Added browser column to Clicks and Conversions tables' },
          { name: 'Changed column name from “Is Private” to Privacy in Offers page' },
          { name: 'Added USDT payment to invoice' },
          { name: 'Added currency to Affiliate profile' },
          { name: 'Multiple Tracking Domains' },
          { name: 'Added new step to Offer creation' },
          { name: 'Added Affiliate ID field to conversion table' },
          { name: 'Removed “Brand” field from Offers' },
          {
            name: 'Affiliate Tracking Domains, Affiliates Access, Trackers and Targeting under the Rules tab, separated it into different tabs',
          },
          { name: 'Updated placeolders for Offers' },
          { name: 'Added ability to change total notification count on the Dashboard' },
          { name: 'Changed Statistics widget on the Dashboard' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed editing of Affiliate invoice' },
          { name: 'Fixed Conversions table' },
          { name: 'Fixed Notification icon highlight' },
          { name: 'Fixed Deals Updater' },
          { name: 'Fixed pagination' },
          { name: 'Fixed Report Builder and Drilldown Reports presets list' },
          { name: 'Fixed Paypout and Revenue modals display in Goal profile' },
          { name: 'Fixed CRM Botification table' },
          { name: 'Fixed Conversion' },
        ],
      },
    ],
  },
  {
    date: '2022-02-21',
    titleUpdate: 'New features',
    version: '0.9.15',
    img: 'assets/new-img/changelog/changelog-1.0.07.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added button to login as Advertiser' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Fixed Deals Updater' }, { name: 'Fixed Presets' }, { name: 'Fixed General Chart' }],
      },
    ],
  },
  {
    date: '2022-01-31',
    titleUpdate: 'New CRM Design',
    version: '0.9.14',
    img: 'assets/new-img/changelog/changelog-1.0.06.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added "Affiliate Goal Privacy" button to goal profile' }],
      },
    ],
  },
  {
    date: '2022-01-20',
    titleUpdate: 'New features',
    version: '0.9.13',
    img: 'assets/new-img/changelog/changelog-1.0.05.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added full payment info to invoice profile in Affiliate area' }, { name: 'New Slack Notifications' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Added new step to offer creation' }],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed Invoice create widget in Affiliate area' },
          {
            name: 'Fix validation to creating affiliate via registration page',
          },
        ],
      },
    ],
  },
  {
    date: '2022-01-11',
    titleUpdate: 'New features',
    version: '0.9.12',
    img: 'assets/new-img/changelog/changelog-1.0.04.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          /** 1.0.06 */
          { name: 'Added "Payout Tree"' },
          { name: 'Added "Revenue Tree"' },
          { name: 'Added "Targeting Tree"' },
          /** 1.0.05 */
          { name: 'Added "Paused" field status to offer modal' },
          { name: 'Added "Pre-Moderation" field privacy to offer modal' },
          { name: 'Added "Redirect Type" field to offer modal' },
          { name: 'Added "Category" field to offer modal' },
          { name: 'Added "Conversion Auto Approve" field to offer modal' },
          { name: 'Added "Approve" field to Conversions modal' },
          { name: 'Added Manual Postbacks approval' },
          { name: 'Added Partner sign up settings' },
          /** 1.0.04 */
          { name: 'Added Affiliates Balances Table' },
          { name: 'Added Affiliate Payment Info Widget in profile affiliate' },
          { name: 'Added Affiliate Invoices Widget in profile affiliate' },
        ],
      },
      /** Common */
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'New Offer Create modal aka "Wizard"' }, { name: 'New Affiliate Access modal design (drag & drop)' }],
      },
    ],
  },
  {
    date: '2021-07-12',
    titleUpdate: 'New Unsecuder Mode Time',
    version: '0.9.11',
    img: 'assets/new-img/changelog/changelog-1.0.03.svg',
    changesGroups: [
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Changed Unsecured Mode time to 30 minutes' }],
      },
    ],
  },
  {
    date: '2021-06-29',
    titleUpdate: 'Conversions for test',
    version: '0.9.10',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-1.0.02.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added modal with field "For test" in Conversions Table' }],
      },
    ],
  },
  {
    date: '2021-06-16',
    titleUpdate: 'Advertiser Brand',
    version: '0.9.9',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.12.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added field "Advertiser Brand" in offer create/edit modal' },
          { name: 'Added field "Advertiser Brand" in "Offer Profile"' },
          { name: 'Added field "Advertiser Brand" in "Deals Updater"' },
          { name: 'Added field "Advertiser Brand" "Click Profile"' },
          { name: 'Added "Affiliate Global Postback" single page' },
          { name: 'Redesign "Dashboard Welcome" widget' },
          { name: 'New "Notifications" widget on dashboard' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [{ name: 'Hidden activity filters by default' }],
      },
    ],
  },
  {
    date: '2021-05-31',
    titleUpdate: 'Affiliate Manager',
    version: '0.9.8',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.11.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'In Setting Navigation created "Affiliate Manager Page"' },
          { name: 'In Setting Navigation created "Notification Settings"' },
          { name: 'In Affiliate Trackers added "Global Affiliate Trackers"' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'In advertiser create/edit modal added group "Additional"' },
          { name: 'In affiliate create/edit modal added field "Company"' },
          { name: 'In CRM Setting showed "uuid" an "id"' },
          { name: 'New Design Notifications Sidebar. Added "Affiliate sign up" tab' },
        ],
      },
    ],
  },
  {
    date: '2021-05-14',
    titleUpdate: 'Affiliate Groups and Affiliate Metrics',
    version: '0.9.7',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.10.svg',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Affiliates Group Table' },
          { name: 'Affiliates Group Profile' },
          { name: 'In profile Affiliate “Metrics and Conditions“ which affiliate agreed' },
          { name: 'In profile Advetiser “Metrics and Conditions“ which advetiser created' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'In Offer Create Modal added Field "Offer is white"' },
          { name: 'In Offer Create Modal to Field "Languages" added "All Languages"' },
          { name: 'In Offer Create Modal to Field "Countries" added "All Countries"' },
        ],
      },
    ],
  },
  {
    date: '2021-04-08',
    titleUpdate: 'Refactoring and fixed Bugs',
    version: '0.9.6',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.09.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Offers Table' },
          { name: 'Clicks Table' },
          { name: 'Conversions Table' },
          { name: 'Data Exporter' },
          { name: 'Drilldown Presets' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added field selectors with Country And Languages in Offers Table' },
          { name: 'Added field selectors to filters Offers Table' },
          { name: 'Added sort tables columns with drag & drop' },
          { name: 'Added Aff Sub fields to Clicks Table' },
          { name: 'Added Aff Sub fields to Conversions Table' },
          { name: 'Added Aff Sub fields to Data Exporter / Conversions' },
          { name: 'Added Aff Sub fields to Drilldown Presets' },
          { name: 'Autofocus in tables on field Search By Id' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [{ name: 'Fixed Search By Id in tables' }],
      },
    ],
  },
  {
    date: '2021-03-25',
    titleUpdate: 'Custom Revenue and Payout Business Models',
    version: '0.9.5',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.08.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Revenue Business Models Table' },
          { name: 'Payout Business Models Table' },
          { name: 'Goals Table' },
          { name: 'Conversions Table' },
          { name: 'Affiliate Goal Table' },
          { name: 'Countries Goal Table' },
          { name: 'Affiliate Goal Country Table' },
          { name: 'Offer Conversion Link Modal' },
          { name: 'Data Exporter' },
          { name: 'Drilldown Presets' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added field selectors to Goals Table' },
          { name: 'Added field selectors to Goals Table Filters' },
          { name: 'Added field selectors to Conversions Table' },
          { name: 'Added field selectors to Affiliate Goal Table' },
          { name: 'Added field selectors to Countries Goal Table' },
          { name: 'Added field selectors to Affiliate Goal Country' },
          { name: 'Added field selectors to Drilldown Presets' },
          { name: 'Added field selectors to Data Exporter / Conversions' },
          { name: 'Added field selectors to Offer Conversion Link Modal' },
        ],
      },
    ],
  },
  {
    date: '2021-03-18',
    titleUpdate: 'Custom Goal Types in CRM',
    version: '0.9.4',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.07.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Goal Types Table' },
          { name: 'Offer Goals Table' },
          { name: 'Conversions Table' },
          { name: 'Affiliate Trackers Table' },
          { name: 'Affiliate Goals Table' },
          { name: 'Dashboard Traffic Data' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added field selectors to Goals Table' },
          { name: 'Added field selectors to Goals Table Filters' },
          { name: 'Added field selectors to Conversions Table' },
          { name: 'Added field selectors to Affiliate Trackers Table' },
          { name: 'Added field selectors to Affiliate Goal Table' },
          { name: 'Added field selectors to Drilldown presets' },
          { name: 'Added field selectors to Data Exporter / Conversions' },
        ],
      },
    ],
  },
  {
    date: '2021-02-26',
    titleUpdate: 'CRM Metrics with fixes',
    version: '0.9.3',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.06.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Added CRM Metrics' },
          { name: 'Added field selectors to Condition section in Drill Down' },
          { name: 'Added a sidebar dropdown menu' },
          { name: 'Added a quick view button to the generated data in Report builder' },
          { name: 'Added new columns Revenue and Payout in Top Offers and Top Countries widgets' },
          { name: 'Added hide columns if more then 5 fields in Report builder' },
          { name: 'Added GDPR mode to all private data in Report Builder' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Changed column name and changed activity icons' },
          { name: 'Added new field to Offer create modal window' },
          { name: 'Add help description in Affiliate Invoices' },
          { name: 'Changed preset types for ADVERTISER AREA, AFFILIATE AREA, NETWORK CRM' },
          { name: 'Added currency and user statuses data format in Report builder' },
          { name: 'Changed all tables for new components' },
          { name: 'Added "report builder" button to the "Offer", "Clicks", "Conversions" pages' },
          { name: 'Added "status/active" button to modal windows' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed alert message If change status' },
          { name: 'Fixed Country - group in Drill Down' },
          { name: 'Fixed “Archived” filter in Affiliate Trackers page' },
          { name: 'Fixed empty date in "Quick View" modal windows' },
        ],
      },
    ],
  },
  {
    date: '2021-02-17',
    titleUpdate: 'Latest release with fixes',
    version: '0.9.2',
    desc: `Improved UI experience for safer and clearer usage!`,
    img: 'assets/new-img/changelog/changelog-0.9.05.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'New Jira Helpdesk widget (only for desktop version)' },
          { name: 'New "Active"-"Archived" feature' },
          { name: 'New Changelog page' },
          { name: 'New Unsecured Mode (beta)' },
          { name: 'New Accounting Mode' },
          { name: 'Notifications (beta)' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Addeded formatting for all monetary values in widgets' },
          { name: 'Addeded coloring to monetary values: 0 values - gray color, positive values is a green color' },
          { name: 'Addeded default currency icon in invoices' },
          { name: '“Active” switcher visual improvements' },
          { name: 'Changed color in active icons' },
          { name: 'Merged Other data and Timestamps into one widget' },
          { name: 'Addeded "Position" columns in "Top Countries" and "Top Offers" widgets' },
          { name: 'Changed Preset List page from tabs to dropdown' },
          { name: 'Moved “API Tokens” page to Settings Navigation' },
          { name: 'Moved “Tracking Domains” page to Settings Navigation' },
          { name: 'Moved the “Preset Lists” page to Settings Navigation' },
          { name: 'Moved “Events Logs” page to Settings Navigation' },
          { name: 'Addeded multi select value to Conditions section in Data Exporter' },
          { name: 'Addeded GDPR modal for unsecured mode' },
          { name: 'Moved Articles and Docks from Sidebar to “How to use it“ button' },
          { name: 'Addeded copy to clipboard button to Hash' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed sorted the placement of column names in filters to tables' },
          { name: 'Fixed title names' },
          { name: 'Fixed alignment of values ​​in widget columns' },
          { name: 'Fixed color for “empty” value in tables' },
          { name: 'Fixed incorrect preloaders' },
        ],
      },
    ],
  },
  {
    date: '2021-01-30',
    titleUpdate: 'Invoices in CRM',
    version: '0.9.1',
    desc: ``,
    img: 'assets/new-img/changelog/changelog-0.9.04.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [{ name: 'Added "Affiliate Invoice" page' }],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added icon for Blocked and Pending status' },
          { name: 'Make auto-select if only one option in dropdown' },
          { name: 'Added “Quick View” in all places where we have some id' },
          { name: 'Redesign “Hello” widget in Dashboard page' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Bug fix Search buttons' },
          { name: 'Bug fix in profile Start Link' },
          { name: 'Fixed errors in some titles' },
        ],
      },
    ],
  },
  {
    date: '2020-12-09',
    titleUpdate: 'New design',
    version: '0.9.0',
    desc: ``,
    img: 'assets/new-img/changelog/changelog-0.9.02.png',
    changesGroups: [
      {
        label: { title: 'Features', color: 'green' },
        listChanges: [
          { name: 'Creation of a new design for the "Dashboard" page' },
          { name: 'Added Top Offers widget' },
          { name: 'Added Top Countries widget' },
        ],
      },
      {
        label: { title: 'Common', color: 'primary' },
        listChanges: [
          { name: 'Added last leads widget for new Dashboard' },
          { name: 'Added welcome widget (new design)' },
          { name: 'Addeded display by default of active elements in tables' },
          { name: 'Added table header color' },
          { name: 'Added “Affiliate Tracking Domains” widget' },
        ],
      },
      {
        label: { title: 'Bugs', color: 'red' },
        listChanges: [
          { name: 'Fixed bugs in the Click tab' },
          { name: 'Fixed bugs in Report Builder' },
          { name: 'Fixed bugs in table filters' },
          { name: 'Multiple minor fixes' },
          { name: 'Bug fix in CRM Accounts panel' },
          { name: 'Bug fix in  Manage Advertisers field' },
        ],
      },
    ],
  },
];
